import React from 'react';

const Paragraph = (props) => {
  const { extra } = props;
  return <p className={`text-lg text-gray-600 ${extra}`}>{props.children}</p>;
};
const Text = (props) => {
  return (
    <div className='text-lg lg:text-xl text-gray-600 font-normal'>
      {props.children}
    </div>
  );
};
const Title = (props) => {
  const { level, extra, bold, div } = props;
  const level_uc = level.toUpperCase();

  const selectBold = !bold ? '' : 'font-bold';

  const extraStyles = `${extra} ${selectBold}`;
  if (level_uc === 'H1') {
    const genClass = `pt-[16px] text-5xl md:text-6xl ${extraStyles}`;
    if (div) {
      return <div className={genClass}>{props.children}</div>;
    }
    return <h1 className={genClass}>{props.children}</h1>;
  }

  if (level_uc === 'H2') {
    const genClass = `pt-[16px] text-4xl ${extraStyles}`;
    if (div) {
      return <div className={genClass}>{props.children}</div>;
    }
    return <h2 className={genClass}>{props.children}</h2>;
  }

  if (level_uc === 'H3') {
    const genClass = `pt-[16px] text-3xl ${extraStyles}`;
    if (div) {
      return <div className={genClass}>{props.children}</div>;
    }
    return <h3 className={genClass}>{props.children}</h3>;
  }

  if (level_uc === 'H4') {
    const genClass = `pt-[16px] text-2xl ${extraStyles}`;
    if (div) {
      return <div className={genClass}>{props.children}</div>;
    }
    return <h4 className={genClass}>{props.children}</h4>;
  }

  if (level_uc === 'H5') {
    const genClass = `pt-[16px] text-xl ${extraStyles}`;
    if (div) {
      return <div className={genClass}>{props.children}</div>;
    }
    return <h5 className={genClass}>{props.children}</h5>;
  }

  if (level_uc === 'H6') {
    const genClass = `pt-[16px] text-lg ${extraStyles}`;
    if (div) {
      return <div className={genClass}>{props.children}</div>;
    }
    return <h6 className={genClass}>{props.children}</h6>;
  }

  console.error('Wrong title defination');
  return <div>{props.children}</div>;
};

export { Paragraph, Title, Text };
