import React from 'react';
import Layout from './layout';
import { PostContainer } from '../old_components/post_container';
import SEO from '../components/seo';
import useScreenSize from 'use-screen-size';

const Index = (props) => {
  const { nodes } = props.pageContext;
  const seoParams = {
    title: 'Careery Blog',
    description:
      'Follow the Careery blog for announcements, user stories, and career guides.',
    url: `/blog`,
    image: '/src/media/og-image.png',
    titleTemplate: '',
  };
  const screenSize = useScreenSize();
  return (
    <Layout screenSize={screenSize}>
      <SEO params={seoParams} />
      <div className='py-[96px] px-[20px] md:px-[40px] xl:px-0 max-w-5xl mx-auto'>
        <BlogHeader />
        <PostsGrid posts={nodes} />
      </div>
    </Layout>
  );
};

const BlogHeader = () => {
  return (
    <div>
      <h1 className='text-5xl md:text-7xl text-center font-medium  pb-[48px]'>
        Careery Blog
      </h1>
      <div className='text-center text-lg text-gray-500 font-normal'>
        Follow the Careery blog for announcements, user stories, and career
        guides.
      </div>
    </div>
  );
};

const PostsGrid = ({ posts }) => {
  if (posts === null) {
    return <div className='text-center'>No posts at the moment.</div>;
  }

  const half = Math.ceil(posts.length / 2);
  const firstHalf = posts.slice(0, half);
  const secondHalf = posts.slice(-half + 1);

  return (
    <div className='grid  grid-flow-row-dense grid-cols-1 lg:grid-cols-2  gap-[32px] pt-[96px]'>
      <PostsCol>
        {firstHalf.map((post) => (
          <PostContainer key={post.id} post={post} />
        ))}
      </PostsCol>
      <PostsCol>
        {secondHalf.map((post) => (
          <PostContainer key={post.id} post={post} />
        ))}
      </PostsCol>
    </div>
  );
};

const PostsCol = (props) => {
  return <div className='flex flex-col space-y-[48px]'>{props.children}</div>;
};
export default Index;
