import React from 'react';
import { Title } from './elements';
import { Link } from 'gatsby';
import { split, reverse, join, dropWhile } from 'rambda';
import { GatsbyImage } from 'gatsby-plugin-image';
const PostContainer = ({ post }) => {
  return (
    <Link
      to={`/blog/${post.slug}`}
      className='cursor-pointer'
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <div className='cursor-pointer'>
        <div className='shadow-xl'>
          <GatsbyImage
            image={post.coverImage.gatsbyImageData}
            alt={post.fileName}
            layout={'fullWidth'}
          />
          <TextContainer post={post} />
        </div>
      </div>
    </Link>
  );
};

const TextContainer = ({ post }) => {
  const firstPostParagraph = post.content.raw.children
    .filter((el) => el.type === 'paragraph')[0]
    .children.map(({ text }) => text)
    .join(' ');

  const maxLength = 325;
  const stringHead = firstPostParagraph.slice(0, maxLength);
  const trailedFirstParagraph = trailToLastDot(stringHead);
  // const trailedFirstParagraph = [];

  return (
    <div className='py-[68px] px-[24px]   '>
      <Title level='h4'>{post.title}</Title>
      <div className='text-[#616161] text-[18px] my-[24px] font-light break-words overflow-ellipsis overflow-hidden   '>
        {trailedFirstParagraph}
      </div>
    </div>
  );
};

const trailToLastDot = (str) => {
  const splitedString = split('', str);
  const removedToLastDot = dropWhile((x) => x !== '.', reverse(splitedString));
  const backToString = join('', reverse(removedToLastDot));
  return backToString;
};

export { PostContainer };
